import 'es6-promise/auto'

import Vue from 'vue';
import App from './App.vue';
import store from '@/store';

Vue.config.productionTip = false;

import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios);

// Analytics causes error when loading from html file
import VueGtm from 'vue-gtm';
Vue.use(VueGtm, {
    id: 'GTM-PNRXQPH',// or ['GTM-xxxxxxx', 'GTM-xxxxxxx'], // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
    //queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    //    gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
    //    gtm_preview: 'env-4',
    //    gtm_cookies_win: 'x'
    //},
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: false, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional) 
    //vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    //ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
});


import Vuetify from 'vuetify'
Vue.use(Vuetify);
const opts = {
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: "#00698f", // datahub #135e70 freight #00698f teal
                secondary: "#c4d600", // green
                accent: "#61daff", // datahub: #418fde freight #61daff blue
                nav_bar: "#00698f",
                footer_toolbox: "#00698f",
                footer_navbar: "#029ad1",
                chip_bg: "#c4d600",
                //chip_text: "black",
                filter_bg: "#00698f",
                lines: "#c4d600",

            } // #003b4c
            
        }
        
    }
}
import 'vuetify/dist/vuetify.min.css' // Ensure you are using css-loader
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import vueCustomElement from 'vue-custom-element'
Vue.use(vueCustomElement)

import 'document-register-element/build/document-register-element'
var app = (App as any)
app.vuetify = new Vuetify(opts)
app.store = store

Vue.customElement('transit-benchmark', app)

//var vm = new Vue({
//    vuetify: new Vuetify(opts),
//    render: h => h(App)
//}).$mount('#app');










