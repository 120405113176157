import { ActionTree } from 'vuex';
import { AuthState } from './types';
import { RootState } from '../types';
import { endpoints } from '../../endpoints'
import Vue from 'vue';
import { isNullOrUndefined } from 'util';

//import JQuery from 'jquery'
//let $ = JQuery

function enableFiltersWithConstraints(user: any, dispatch: any) {
    for (var key in user.constraints) {
        if (user.constraints.hasOwnProperty(key)) {
            dispatch('filters/setFilterEnabled', { filter: key, value: true }, { root: true });
        }
    }
}

export const actions: ActionTree<AuthState, RootState> = {

    // This is the default Django login action. (Obsolete - use Trench login with MFA below)
    login({ commit, dispatch }, user) {

        return new Promise((resolve, reject) => {

            commit('auth_request');

            Vue.axios({ url: endpoints.authUrl, data: user, method: 'POST' })
                .then((response: any) => {

                    const token = response.data.token;
                    const user = response.data.user;

                    enableFiltersWithConstraints(user, dispatch);

                    Vue.axios.defaults.params = { user: user.username } // Add default query param to requests to avoid caching issues when changing user login.
                    commit('auth_success', { 'token': token }); // Commit the user and token
                    //Vue.axios.defaults.headers.common['Authorization'] = 'JWT ' + token; // Set the token as a default header for all requests.

                    console.log('Welcome: ' + user.username);
                    commit('auth_success', { 'user': user, 'token': token }); // Commit the user and token
                    resolve(response);
                })
                .catch((error: any) => {
                    commit('auth_error');
                    reject(error);
                });
        });
    },

    // This is the Django-Trench login action which has MFA capabilities.
    loginMFA({ commit, dispatch }, payload) {

        return new Promise((resolve, reject) => {

            commit('auth_request');

            // If MFA is not activated, there is no 'ephemeral_token' present in the response. There is just the 'token' which is all that is needed to authenticate.
            // If MFA is activated, an 'ephemeral_token' is present in the response after the first login stage. A second login stage is then required...
            // https://django-trench.readthedocs.io/en/latest/endpoints.html#login-first-step-jwt-example
            var url = isNullOrUndefined(payload.ephemeral_token) ? endpoints.authUrl : endpoints.authCodeUrl;

            Vue.axios({ url: url, data: payload, method: 'POST' })
                .then((response: any) => {

                    if (response.data.ephemeral_token) {
                        // If authenticating through first login attempt with MFA (i.e a ephemeral_token was received rather than a token)

                        commit('auth_mfa', { 'mfa': response.data, 'username': payload.username });
                        resolve(response);

                    } else if (response.data.access) {
                        // If authenticated (or second login attempt with MFA)

                        dispatch('useAccessToken', response.data).then((response: any) => {
                            resolve(response);
                        }).catch((error: any) => {
                            reject(error);
                        });
                    }
                })
                .catch((error: any) => {
                    commit('auth_error');
                    if (error.response.status === 429) {
                        var message = error.response.data.detail.replace('Request was throttled. Expected available in', 'Too many attempts. Try again in');
                    } else if (!isNullOrUndefined(error.response.data.error)) { // Invalid credentials
                        var message = error.response.data.error;
                    } else if (!isNullOrUndefined(error.response.data.detail)) { // Token error
                        var message = error.response.data.detail;
                    } else if (!isNullOrUndefined(error.response.data.non_field_errors[0])) {
                        var message = error.response.data.non_field_errors[0];
                    } else {
                        var message = null;
                    }
                    var ret = !isNullOrUndefined(message) ? message : error;
                    reject(ret);
                });
        });
    },


    useAccessToken({ commit, dispatch }, tokens) {

        return new Promise((resolve, reject) => {

            //Vue.axios.defaults.headers.common['Authorization'] = 'JWT ' + token; // Set the token as a default header for all requests. (Needed for the following user and MFA method requests)
            commit('auth_success', tokens); // Commit the user and token

            // Get user info such as their profile, authentication methods and user permissions.
            dispatch('getProfile').then((response: any) => {
                commit('auth_success', { 'user': response.data, 'access': tokens.access, 'refresh': tokens.refresh }); // Commit the user and token
                resolve(response);
            }).catch((error: any) => {
                commit('auth_error');
                reject(error);
            });
        });
    },


    refreshToken({ commit, dispatch, state }) {

        return new Promise((resolve, reject) => {
            //console.log('Refreshing Access Token...');
            Vue.axios({ url: endpoints.authRefreshTokenUrl, data: { refresh: state.refresh_token }, method: 'POST' })
                .then((response: any) => {
                    if (response.data) { // For some reason a request with an expired refresh token (401) doesn't go through as an error, so use this catch.
                        commit('auth_success', response.data); // Commit the access token
                        console.log('Authorization Refreshed!');
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }).catch((error: any) => {
                    reject(error);
                });
        });
    },


    // Confirm the activation method.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#mfa-method-activation-confirmation
    MFA_activate_confirm({ commit, dispatch }, data) {

        return new Promise((resolve, reject) => {
            commit('auth_request');
            Vue.axios({ url: endpoints.authActivateConfirmUrl(data.method), data: data, method: 'POST' })
                .then((response: any) => {
                    commit('auth_success_mfa');
                    console.log('Backup Codes');
                    console.log(response.data.backup_codes);
                    dispatch('MFA_UserActiveMethods')
                        .then((response_user_methods: any) => {
                            resolve(response);
                        }).catch((error: any) => {
                            reject(error);
                        });
                })
                .catch((error: any) => {
                    commit('auth_error');
                    reject(error);
                });
        });
    },

    // Request a new MFA method.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#mfa-method-activation
    MFA_activate({ commit, dispatch }, method) {

        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.authActivateUrl(method.method), method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {

                    // Parse some specific errors. Note: May need to update these for future Django-Trench versions.
                    var data = error.response.data;

                    // Parse phone number missing from database. Note: probably can't even parse this error in production?
                    if ((typeof data == 'string') && data.includes('KeyError') && data.includes('phonenumber')) {
                        error.response.data = { non_field_errors: ['Unable to send SMS. The registered mobile phone number is invalid.'] };
                    }
                    // Parse e-mail address missing from database.
                    if (!isNullOrUndefined(data.email)) {
                        error.response.data = { non_field_errors: ['Unable to send e-mail. The registered e-mail address is invalid.'] };
                    }

                    reject(error);
                });
        });
    },

    // Change the primary method.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#change-user-s-primary-mfa-method
    MFA_change_primary_method({ commit, dispatch }, data) {

        return new Promise((resolve, reject) => {
            commit('auth_request');
            Vue.axios({ url: endpoints.authChangePrimaryMethod, data: data, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                }).catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Deactivates the specified MFA method.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#mfa-method-deactivation
    MFA_deactivate({ commit, dispatch, state }) {

        return new Promise((resolve, reject) => {

            // If authenticated, the method comes from the store. If not authenticated (assume in process on MFA screen), the method comes from the MFA 2nd step.
            var method = state.mfa_methods.length > 0 ? (state.mfa_methods as any)[0].name : state.mfa_current ? state.mfa_current.method : null;

            if (!method) {
                resolve(method);
            }

            Vue.axios({ url: endpoints.authDeactivateUrl(method), method: 'POST' })  // Note: It seems you can't actually deactivate if not authenticated anyway.
                .then((response: any) => {
                    commit('auth_mfa_deactivate');

                    // Re-fetch the MFA methods for the user.
                    dispatch('MFA_UserActiveMethods')
                        .then((response_user_methods: any) => {
                            resolve(response);

                        }).catch((error: any) => {
                            reject(error);
                        });
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Gets a list of the Available MFA methods which have been enabled on the server.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#get-configuration
    MFA_config({ commit, dispatch }) {

        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.authConfigUrl, method: 'GET' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Requests a new MFA code.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#send-the-code
    MFA_request_code({ commit, dispatch, state }) {

        return new Promise((resolve, reject) => {
            commit('auth_request');
            Vue.axios({ url: endpoints.authRequestCodeUrl, method: 'POST', data: { method: state.mfa_current.method } })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    commit('auth_error');
                    reject(error);
                });
        });
    },
    
    // Gets a list of the MFA methods that the user has enabled on their account.
    // https://django-trench.readthedocs.io/en/latest/endpoints.html#get-user-s-active-mfa-methods
    MFA_UserActiveMethods({ commit, dispatch }) {

        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.authUserActiveMethodsUrl, method: 'GET' })
                .then((response: any) => {
                    commit('auth_mfa_methods', response.data);
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    //user_info({ commit, dispatch }) {

    //    return new Promise((resolve, reject) => {
    //        Vue.axios({ url: endpoints.userUrl, method: 'GET' })
    //            .then((response: any) => {

    //                const user = response.data.user;
    //                console.log('Welcome: ' + user.username);

    //                Vue.axios.defaults.params = { user: user.username } // Add default query param to requests to avoid caching issues when changing user login.

    //                enableFiltersWithConstraints(user, dispatch);

    //                dispatch('MFA_UserActiveMethods')
    //                    .then((response_user_methods: any) => {
    //                        resolve(response);
    //                    }).catch((error: any) => {
    //                        reject(error);
    //                    });
    //            })
    //            .catch((error: any) => {
    //                commit('auth_error');
    //                reject(error);
    //            });
    //    });
    //},
    
    logout({ commit, dispatch }) {
        return new Promise<void>((resolve, reject) => {
            commit('logout');
            delete Vue.axios.defaults.headers.common['Authorization'];
            if (!isNullOrUndefined(Vue.axios.defaults.params)) {
                delete Vue.axios.defaults.params['user'];
            }
            //dispatch('filters/clearFilters', {}, { root: true }); // This shouldn't be here hence commented out (2024-05-11)... leave here for a bit, but DELETE ME eventually!
            resolve();
        });
    },

    // Registers a new user account. A verification link will be sent their e-mail address.
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/register.html
    register({ commit, dispatch }, user) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.registerUrl, data: user, method: 'POST' })
                .then((response: any) => {
                    console.log('Welcome: ' + user.username);
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Verifies a user registration by signing against the information provided in the verification link (above)
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/register.html
    verifyRegistration({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.verifyRegistrationUrl, data: data, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Sends a links to a users e-mail address so they can reset their password.
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/reset_password.html
    resetPasswordLink({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.resetPasswordLinkUrl, data: data, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Resets a users password by signing against the information provided in the reset password link (above).
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/reset_password.html
    resetPassword({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.resetPasswordUrl, data: data, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Gets the user profile information.
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/profile.html
    getProfile({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.profileUrl, data: data, method: 'GET' })
                .then((response: any) => {

                    const user = response.data;
                    console.log('Welcome: ' + user.username);

                    Vue.axios.defaults.params = { user: user.username } // Add default query param to requests to avoid caching issues when changing user login.

                    enableFiltersWithConstraints(user, dispatch);

                    dispatch('MFA_UserActiveMethods')
                        .then((response_user_methods: any) => {
                            resolve(response);
                        }).catch((error: any) => {
                            reject(error);
                        });
                })
                .catch((error: any) => {
                    commit('auth_error');
                    reject(error);
                });
        });
    },

    // Updates the user profile information.
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/profile.html
    updateProfile({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.profileUrl, data: data, method: 'PUT' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Changes a users password if they are authenticated. They must provide their current password.
    // https://django-rest-registration.readthedocs.io/en/latest/detailed_configuration/change_password.html
    changePassword({ commit, dispatch }, data) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.changePasswordUrl, data: data, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    },

    // Verifies a users recapture token.
    // https://github.com/llybin/drf-recaptcha
    verifyRecaptcha({ commit, dispatch }, token) {
        return new Promise((resolve, reject) => {
            Vue.axios({ url: endpoints.verifyRecaptchaUrl, data: {recaptcha: token}, method: 'POST' })
                .then((response: any) => {
                    resolve(response);
                })
                .catch((error: any) => {
                    reject(error);
                });
        });
    }
};