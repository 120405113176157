import { ActionTree } from 'vuex';
import { AssistantState, MessageState } from './types';
import { RootState } from '../types';
import { getters } from "@/store/assistant/getters";
import { state } from "@/store/assistant/index";
import { endpoints } from '../../endpoints'
import Vue from 'vue';
import buildUrl from 'build-url';


export const actions: ActionTree<AssistantState, RootState> = {

    askQuestion({ commit, dispatch}, payload: MessageState): any {


        // Note doesn't display dialog like old function does. Probably need an extra property (like modal/browser tab)
        payload.role = 'user'; // Add user role to question.

        commit('setSubject', payload.subject);
        commit('setQuestion', payload.content);
        commit('addMessage', payload);

        commit('setLoading', true);

        var endpoint = buildUrl(endpoints.assistantUrl, {
            queryParams: { q: payload.content, subject: payload.subject }
        });
        commit('setQuestion', ''); // Clear the question after the request has been defined.

        var use_sse = true;

        if (use_sse) {

            var full_msg: any = {}

            var sseClient = Vue.$sse.create({
                url: endpoint,
                format: 'json',
                //withCredentials: true,
                polyfill: true,
            });

            var onUpdate = function(msg: any) {

                //console.log('Message: ', msg);

                var response = msg.response;

                if (response && (response.content || response.role)) {
                    const deltaRole = response["role"] || "";
                    const deltaContent = response["content"] || "";
                    const combinedObject = {
                        role: full_msg.response.role + deltaRole,
                        content: full_msg.response.content + deltaContent,
                    };
                    full_msg.response = Object.assign({}, full_msg.response, combinedObject);
                } else if (response && response.related_questions) {
                    (full_msg.response as any)['related_questions'] = response.related_questions;
                } else if (msg.question) { // Root elements
                    full_msg = Object.assign({}, full_msg, msg);
                }

                commit('setProgressiveMessage', full_msg.response);
            }


            var onComplete = function(msg: any) {

                console.log('Complete', full_msg);

                dispatch('clearProgressiveMessage');

                var result = full_msg.response as any;
                commit('addMessage', result);
                commit('setLoading', false);

                sseClient.disconnect();
            }

            // Catch any errors (ie. lost connections, etc.)
            sseClient.on('error',
                (e) => {

                    console.error('Lost SSE connection or failed to parse!', e);

                    // If this error is due to an unexpected disconnection, EventSource will
                    // automatically attempt to reconnect indefinitely. You will _not_ need to
                    // re-add your handlers.

                    commit('addMessage',
                        {
                            content: 'Sorry, I am currently overheating!, please try again shortly...',
                            role: 'assistant',
                            status: 'error'
                        });
                    commit('setLoading', false);
                });

            // Handle messages without a specific event
            sseClient.on('message', onUpdate);

            // Handle messages without a specific event
            sseClient.on('complete', onComplete);


            sseClient.connect()
                .then(sse => {
                    console.log("SSE connected!");
                    full_msg = { "response": { "content": "", "role": "", "subject": payload.subject } }
                })
                .catch((err) => {
                    // When this error is caught, it means the initial connection to the
                    // events server failed.  No automatic attempts to reconnect will be made.
                    console.error('Failed to connect to server', err);
                });

        } else {

            Vue.axios({
                url: endpoint
            }).then((response: any) => {
                var result = response.data.response;
                result.subject = payload.subject; // Transfer subject over.
                commit('addMessage', result);
                commit('setLoading', false);
            }, (error: any) => {
                commit('addMessage', { content: 'Sorry, I am currently overheating!, please try again shortly...', role: 'assistant', status: 'error' });
                commit('setLoading', false);
            });

        }
    },

    speek({ commit }, payload: any): any {

        return new Promise((resolve, reject) => {

            // Note: Axios API can't stream, so need to use fetch API.
            fetch(endpoints.assistantSpeekUrl,
                {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": payload.accept || "audio/mpeg",
                    },
                    body: JSON.stringify(payload.body)
                })
                .then(response => {
                    resolve(response);
                }, (error: any) => {
                    reject(error);
                });
        });
    },

    setQuestion({ commit }, question: string) {
        commit('setQuestion', question);
    },

    setSubject({ commit }, subject: string) {
        commit('setSubject', subject);
    },

    setStandAlone({ commit }, isStandAlone: boolean) {
        commit('setIsStandAlone', isStandAlone);
    },

    setSyncWithMap({ commit }, syncWithMap: boolean) {
        commit('setSyncWithMap', syncWithMap);
    },

    setPlayAudioResponses({ commit }, playAudioResponses: boolean) {
        commit('setPlayAudioResponses', playAudioResponses);
    },

    clearMessages({ commit, getters }) {
        commit('setMessages', []);
        commit('setProgressiveMessage', { content: "", role: "" });
    },

    clearProgressiveMessage({ commit, getters }) {
        commit('setProgressiveMessage', {content: "", role: ""} );
    },

    addAssistantMessage({ commit }, content: MessageState) {
        content.role = 'assistant';
        commit('addMessage', content);
    },
};